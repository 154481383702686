<template>
  <div class="">
    <div class="page-title">
      <p class="font-medium text-lg">AI 銷售統計圖表</p>
      <div class="flex items-center text-gray-60 text-sub" style="gap: 20px">
        <p>數據更新時間：{{ chartData.lastUpdatedAt ? formatDate(chartData.lastUpdatedAt) : '-' }}</p>
      </div>
    </div>

    <BeautyAISalesProductAnalysisChart
      v-loading="loading"
      :year.sync="chartSyncData.year"
      :view.sync="chartSyncData.view"
      :data="chartData"
      :yearOptions="yearOptions"
      @yearChange="refresh"
      @viewChange="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import BeautyAISalesProductAnalysisChart from './components/BeautyAISalesProductAnalysisChart.vue'
import { GetBeautyAISalesProductAnalysisChart, GetBeautyAISalesProductAnalysisYearOptions } from '@/api/beautyAI'
import { useShop } from '@/use/shop'
import { map, get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'BeautyAISalesProductAnalysis',
  components: { BeautyAISalesProductAnalysisChart },
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const chartData = ref({})
    const yearOptions = ref([])
    const chartSyncData = reactive({
      year: null,
      view: 'month',
    })

    const refresh = async () => {
      if (!chartSyncData.year) return
      if (loading.value) return
      loading.value = true
      const [res, err] = await GetBeautyAISalesProductAnalysisChart({
        shopId: shopId.value,
        dateTrunc: chartSyncData.view,
        year: chartSyncData.year,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      chartData.value = res
    }

    onBeforeMount(async () => {
      const [res, err] = await GetBeautyAISalesProductAnalysisYearOptions({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      yearOptions.value = map(res.yearOptions, (item) => ({ label: item, value: item }))
      chartSyncData.year = get(res, 'yearOptions[0]') || ''

      refresh()
    })
    return { refresh, chartData, loading, shopId, yearOptions, chartSyncData, formatDate }
  },
})
</script>

<style lang="postcss" scoped>
.page-title {
  @apply flex justify-between;
  @apply mb-[30px];
}
</style>
